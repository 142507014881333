<template>
  <b-col
    lg="4"
    md="6"
    cols="12"
  >
    <b-card>
      <b-overlay
        :show="loadingCurrentSaldo"
        class="h-[12rem] md:h-[14rem]"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity="0.3"
      >
        <div class="d-flex justify-content-between text-2xl mb-1">
          <div class="d-flex my-auto font-semibold">
            Saldo Pending
            <img
              id="balancePendingTooltip"
              class="ml-[6px]"
              src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            >
            <b-popover
              triggers="hover"
              target="balancePendingTooltip"
              placement="bottomleft"
            >Saldo Pending merupakan saldo yang akan kamu terima ketika orderan yang statusnya dikirim telah berubah menjadi diterima.</b-popover>
          </div>
          <b-button
            variant="outline-secondary"
            class="rounded-lg p-0"
            size="sm"
            :to="{ name: $route.meta.routeToOrder, query: { tab: 'dikirim' } }"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="25"
            />
          </b-button>
        </div>
        <p class="text-4xl font-semibold mb-1">
          Rp {{ saldoPending.toLocaleString('id-ID') }}</p>
        <p class="mb-0">
          Dari nilai tersebut, <span class="text-primary">Rp {{ saldoPendingOnProblem.toLocaleString('id-ID') }},-</span> nya perlu dimonitor</p>
      </b-overlay>
    </b-card>
  </b-col>
</template>
<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('dashboard', [
      'saldoPending',
      'saldoPendingOnProblem',
      'loadingCurrentSaldo',
    ]),
  },
}
</script>
